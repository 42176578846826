import React, { useState , useEffect} from "react";
import { Bar } from "react-chartjs-2";
import Layout from "../Componets/Layout";
import { Link } from "react-router-dom";
// import Chart from 'chart.js/auto';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar'


const JeevanLabhCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [sumAssured, setSumAssured] = useState('');
  const [policyTerm, setPolicyTerm] = useState('');
  const [premiumTerm, setPremiumTerm] = useState('');
  const [age, setAge] = useState('');
  const [bonusRate, setBonusRate] = useState('');
  const [annualPremium, setAnnualPremium] = useState('');
  const [results, setResults] = useState(null);

  const handleCalculate = (e) => {
    e.preventDefault();

    if (!sumAssured || !policyTerm || !premiumTerm || !age || !bonusRate || !annualPremium) {
      alert('Please fill all fields');
      return;
    }

    const sumAssuredNumber = parseFloat(sumAssured);
    const bonusRateNumber = parseFloat(bonusRate);
    const annualPremiumNumber = parseFloat(annualPremium);

    // Calculate total premium paid (user input)
    const totalPremiumPaid = annualPremiumNumber * premiumTerm;

    // Calculate bonus accumulation for each year
    let yearlyBonus = (bonusRateNumber * sumAssuredNumber) / 1000;
    let totalBonus = yearlyBonus * policyTerm;

    // Calculate maturity benefit (Sum Assured + Total Bonuses)
    const maturityBenefit = sumAssuredNumber + totalBonus;

    // Accumulate bonus each year for graphical purposes
    const bonusAccumulation = [];
    const cumulativeMaturityBenefit = [];

    for (let i = 1; i <= policyTerm; i++) {
      bonusAccumulation.push(yearlyBonus * i); // Total bonus up to year i
      cumulativeMaturityBenefit.push(sumAssuredNumber + yearlyBonus * i); // Maturity benefit (Sum Assured + bonus till year i)
    }

    // Set results to display
    setResults({
      totalPremiumPaid,
      totalBonus,
      maturityBenefit,
      bonusAccumulation,
      cumulativeMaturityBenefit,
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Total Premium Paid': results.totalPremiumPaid.toFixed(2) },
      { 'Total Bonus Accumulated': results.totalBonus.toFixed(2) },
      { 'Maturity Benefit': results.maturityBenefit.toFixed(2) }
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'Lic Jeevan Labh');
    XLSX.writeFile(wb, 'Lic_Jeevan__Calculator.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
   <Layout  pageTitle="LIC Jeevan Labh Calculator | LICCalculators"
   metaDescription="Use our LIC Jeevan Labh Calculator to estimate premiums and benefits of the LIC Jeevan Labh policy."
   metaKeywords="LIC Jeevan Labh calculator, Jeevan Labh premiums, LIC policy calculator"
   canonicalUrl="https://liccalculators.com/lic-jeevan-labh-calculator"
   robotsContent="index, follow"
   ogTitle="LIC Jeevan Labh Calculator | LICCalculators"
   ogDescription="Calculate premiums and benefits of LIC Jeevan Labh policy with our easy-to-use calculator."
   ogUrl="https://liccalculators.com/lic-jeevan-labh-calculator"
   ogImage="https://liccalculators.com/img/new_logo.png"
   schemaData={{
     "@context": "https://schema.org",
     "@type": "WebPage",
     "url": "https://liccalculators.com/lic-jeevan-labh-calculator",
     "name": "LIC Jeevan Labh Calculator",
     "description": "Estimate premiums and benefits for the LIC Jeevan Labh policy using our calculator.",
     "publisher": {
       "@type": "Organization",
       "name": "LICCalculators",
       "logo": {
         "@type": "ImageObject",
         "url": "https://liccalculators.com/img/new_logo.png"
       }
     }
   }}>

   <div className="container-fluid">
    <div className="row">
      <div className="col-md-1">

      </div>
      <div className="col-md-8 main-section">
        <div className="container">
        <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Lic Jeevan Labh Calculator</li>
                </ol>
                    </nav>
                    <form onSubmit={handleCalculate}>
                    <h1 className='text-center'>Lic Jeevan Labh Calculator</h1>
                    <p className='text-center'>Plan Your Future with Precision </p>
                    <div className="container text-center">
                      <div className="row">
                        <div className="col-md-12">
                        <label htmlFor="Sum Assured" className="form-label label"><b>Sum Assured (INR):</b> <span className="text-danger">*</span> </label>
                        <input
                          type="number"
                          className="form-control"
                          value={sumAssured}
                          id="investmentAmount"
                          onChange={(e) => setSumAssured(e.target.value)}
                          placeholder="Enter sum assured"
                          required
                        />
                        </div>
                      </div>
                      <div className="row input-row">
                        <div className="col-md-12">
                        <label htmlFor="Policy" className="form-label label"><b>Policy Term (Years):</b> <span className="text-danger">*</span> </label>
                        <input
                        type="number"
                        className="form-control"
                        id="investmentAmount" 
                        value={policyTerm}
                        onChange={(e) => setPolicyTerm(e.target.value)}
                        placeholder="Enter policy term"
                      />
                        </div>
                      </div>
                      <div className="row input-row">
                        <div className="col-md-12">
                        <label htmlFor="Premium" className="form-label label"><b>Premium Payment Term (Years):</b>  <span className="text-danger">*</span></label>
                        <input
                          type="number"
                          value={premiumTerm}
                          className="form-control"
                          id="investmentPeriod" 
                          onChange={(e) => setPremiumTerm(e.target.value)}
                          placeholder="Enter premium payment term"
                        />
                        </div>
                      </div>
                      <div className="row input-row">
                        <div className="col-md-12">
                        <label htmlFor="age" className="form-label label"><b>Age:</b>  <span className="text-danger">*</span> </label>
                            <input
                              type="number"
                              className="form-control"
                              value={age}
                             id="annualInterestRate"
                              onChange={(e) => setAge(e.target.value)}
                              placeholder="Enter your age"
                            />
                        </div>
                      </div>
                      <div className="row input-row">
                        <div className="col-md-12">
                        <label htmlFor="Bonus" className="form-label label"><b>Bonus Rate (Per ₹1000):</b> <span className="text-danger">*</span> </label>
                          <input
                            type="number"
                            value={bonusRate}
                            className="form-control"
                            id="annualInterestRate"
                            onChange={(e) => setBonusRate(e.target.value)}
                            placeholder="Enter bonus rate"
                          />
                        </div>
                      </div>
                      <div className="row input-row">
                        <div className="col-md-12">
                        <label><b>Annual Premium (INR):</b> <span className="text-danger">*</span> </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="annualInterestRate"
                                  value={annualPremium}
                                  onChange={(e) => setAnnualPremium(e.target.value)}
                                  placeholder="Enter annual premium"
                                />
                        </div>
                      </div>
                    </div>
                    <div className="row input-row">
                      <div className="col-md-12">
                      <button type="submit" id="annualInterestRate" className='btn btn-success' >Calculate</button>
                      </div>
                    </div>
                    </form>

                    {results && (
        <div className="container">
        <hr></hr>
          <h5> Summary :</h5> <br></br>
          <div className="row">
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Total Premium Paid</th>
                    <td>{formatCurrency(results.totalPremiumPaid.toFixed(2))}</td>
                  </tr>
                  <tr>
                    <th scope="col">Total Bonus Accumulated</th>
                    <td>{formatCurrency(results.totalBonus.toFixed(2))}</td>
                  </tr>
                  <tr>
                    <th scope="col">Maturity Benefit</th>
                    <td>{formatCurrency(results.maturityBenefit.toFixed(2))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button className="btn btn-success" id='annualInterestRate' onClick={exportToExcel} >Export to Excel</button>
          </div>
         <div className="container">
          <div className="row">
          <h5 className='text-center' style={{marginTop:'20px'}}>Data In Graph Formate :</h5>
          <Bar
            data={{
              labels: Array.from({ length: parseInt(policyTerm) }, (_, i) => `Year ${i + 1}`),
              datasets: [
                {
                  label: "Bonus Accumulation",
                  data: results.bonusAccumulation,
                  backgroundColor: "rgba(75, 192, 192, 0.6)",
                  borderColor: "rgba(75, 192, 192, 1)",
                  borderWidth: 1,
                },
                {
                  label: "Cumulative Maturity Benefit",
                  data: results.cumulativeMaturityBenefit,
                  backgroundColor: "rgba(153, 102, 255, 0.6)",
                  borderColor: "rgba(153, 102, 255, 1)",
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    callback: function (value) {
                      return '₹' + value;
                    },
                  },
                },
              },
            }}
          />
          </div>
         </div>

         
        
        </div>
      )}
      <div className='container'>
  <div className='row' style={{marginTop:"50px"}}>
    <hr />
    <h3 className='text-center text-black'>About LIC Jeevan Labh Calculator</h3>
    <hr />
    <p style={{fontFamily:'sans-serif'}}>
      The LIC Jeevan Labh Calculator is a comprehensive tool designed to help you estimate the key financial components of LIC’s popular Jeevan Labh policy. 
      This plan is a non-linked, limited premium-paying endowment policy, offering the benefits of life insurance coverage coupled with savings. 
      It provides a lump sum payment at maturity, making it an ideal choice for long-term financial goals such as children's education, marriage, or retirement planning. 
      The calculator helps you understand how much you will need to pay, the bonuses you may accumulate, and the total maturity amount you will receive at the end of the policy term.
    </p>

    <h4>How the Calculator Works:</h4>
    <div style={{fontFamily:"sans-serif"}}>
      <p>The LIC Jeevan Labh Calculator works by estimating several critical aspects of your policy based on the input values you provide. These include:</p>
      <ul>
        <li>
          <b>Monthly or Annual Premium:</b> This is the amount you need to pay regularly, depending on your chosen sum assured, policy term, and the premium payment term. 
          The calculator gives you the option to calculate both monthly and annual premiums for better financial planning.
        </li>
        <li>
          <b>Total Premium Paid:</b> This is the cumulative amount you will pay throughout the premium payment period, helping you understand your total financial commitment.
        </li>
        <li>
          <b>Maturity Amount:</b> The total amount you will receive at the end of the policy term. This includes both the sum assured and the bonuses declared by LIC during the policy period, 
          ensuring a good return on investment.
        </li>
      </ul>
    </div>

    <h4>Why Choose LIC Jeevan Labh?</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Limited Premium Payments:</b> One of the key advantages of LIC Jeevan Labh is that you do not need to pay premiums throughout the entire policy term. You pay premiums for a limited period, but enjoy benefits throughout the policy tenure.</li>
        <li><b>Life Coverage:</b> The plan provides financial protection to your family in case of an unfortunate event, while also acting as a savings vehicle for long-term goals.</li>
        <li><b>Bonuses:</b> As a participating policy, LIC Jeevan Labh is eligible for bonuses, which are added to the sum assured, thereby boosting the final payout.</li>
        <li><b>Flexibility:</b> The plan offers flexible premium payment terms, making it suitable for people with varying financial capacities and timelines.</li>
        <li><b>Tax Benefits:</b> Both the premiums paid and the maturity benefits received qualify for tax exemptions under Section 80C and 10(10D) of the Income Tax Act.</li>
      </ul>
    </div>

    <h4>Input Parameters:</h4>
    <div style={{fontFamily:"sans-serif"}}>
      <p>To get accurate estimates, the LIC Jeevan Labh Calculator requires you to input the following parameters:</p>
      <ul>
        <li>
          <b>Sum Assured:</b> The amount that is guaranteed to be paid to the policyholder at maturity or to their nominees in case of an untimely demise during the policy term.
        </li>
        <li>
          <b>Policy Term (Years):</b> The total duration for which the policy remains active. This can range from 16 to 25 years, depending on the policyholder’s preferences and financial goals.
        </li>
        <li>
          <b>Premium Payment Term (Years):</b> The duration for which you are required to pay premiums. LIC Jeevan Labh offers flexible options, with premium payment terms of 10, 15, or 16 years.
        </li>
        <li>
          <b>Bonus Rate:</b> The annual bonus declared by LIC for every ₹1000 of the sum assured. These bonuses are added to your policy and significantly enhance your maturity payout.
        </li>
      </ul>
    </div>

    <h4>Bonus and Maturity Calculation:</h4>
    <p style={{fontFamily:'sans-serif'}}>
      The LIC Jeevan Labh Calculator provides a detailed breakdown of the annual bonuses that accumulate over the policy term. 
      These bonuses, when added to the sum assured, significantly increase the total maturity amount. The calculator helps users understand the correlation between their premium payments and the bonuses accrued, 
      offering transparency into how the policy grows over time. By providing a clear estimate of the maturity payout, it aids in planning long-term financial goals.
    </p>

    <h4>Benefits of Using the LIC Jeevan Labh Calculator:</h4>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li>
          <b>Financial Planning:</b> The calculator helps you estimate the premiums and maturity benefits, enabling you to align the policy with your financial objectives, whether it’s planning for your children’s future, retirement, or any other long-term goal.
        </li>
        <li>
          <b>Transparency:</b> By offering a clear breakdown of how bonuses accumulate and contribute to the final maturity amount, the calculator provides a transparent overview of your policy.
        </li>
        <li>
          <b>Export to Excel:</b> Some calculators offer an option to export your calculated results to Excel, which can be beneficial for tracking your calculations over time and comparing different plans.
        </li>
        <li>
          <b>Comparing Scenarios:</b> You can experiment with different inputs (such as sum assured and premium payment terms) to compare how changing these factors impacts your premiums and maturity amount, helping you make an informed decision.
        </li>
      </ul>
    </div>

    <h4>Additional Features of the LIC Jeevan Labh Policy:</h4>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li>
          <b>Loan Facility:</b> The LIC Jeevan Labh policy offers a loan facility, allowing you to borrow against the policy after paying premiums for a specified number of years. This provides liquidity in times of financial need.
        </li>
        <li>
          <b>Surrender Value:</b> If you need to exit the policy prematurely, you can surrender it after paying premiums for at least three years. The surrender value will be less than the total premiums paid, but it still offers a partial refund.
        </li>
        <li>
          <b>Accidental Death and Disability Benefit Rider:</b> Policyholders can enhance their coverage by opting for additional riders, such as the Accidental Death and Disability Benefit, providing extra security at a nominal additional cost.
        </li>
      </ul>
    </div>

    <h4>FAQs About LIC Jeevan Labh Calculator:</h4>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li>
          <b>Can I change the premium payment term during the policy term?</b><br />
          No, once you choose a premium payment term at the beginning of the policy, it cannot be changed during the policy term. It’s important to select a term that suits your financial situation.
        </li>
        <li>
          <b>What happens if I miss a premium payment?</b><br />
          If you miss a premium, you may need to pay it within the grace period (usually 30 days). Failure to do so could result in policy lapse, but LIC offers a revival option if the policy is lapsed.
        </li>
        <li>
          <b>What is the minimum and maximum sum assured in LIC Jeevan Labh?</b><br />
          The minimum sum assured is ₹2,00,000, and there is no limit to the maximum sum assured, making it flexible based on the policyholder’s financial capabilities and goals.
        </li>
      </ul>
    </div>

    <p style={{fontFamily:"sans-serif"}}>
      The LIC Jeevan Labh Calculator is an essential tool for anyone looking to invest in this policy. 
      It simplifies the calculation process, helping you make an informed decision based on your long-term financial needs and goals.
    </p>
  </div>
</div>



        </div>
      </div>
      <div className="col-md-3">
        <Sidebar/>
      </div>
    </div>
   </div>


   </Layout>
  );
};

export default JeevanLabhCalculator;
