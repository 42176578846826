import React, { useState ,useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import Layout from '../Componets/Layout';
import Sidebar from '../Pages/Sidebar';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';


const JeevanUmangCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [sumAssured, setSumAssured] = useState('');
  const [policyTerm, setPolicyTerm] = useState('');
  const [age, setAge] = useState('');
  const [premiumTerm, setPremiumTerm] = useState('');
  const [bonusRate, setBonusRate] = useState('40'); // Default bonus rate
  const [calculationData, setCalculationData] = useState(null);
  const [validationError, setValidationError] = useState('');

  const calculateJeevanUmang = () => {
    if (!sumAssured || !policyTerm || !age || !premiumTerm || !bonusRate) {
      setValidationError('Please fill out all fields correctly.');
      return;
    }

    const SA = parseFloat(sumAssured);
    const PT = parseInt(policyTerm);
    const PTerm = parseInt(premiumTerm);
    const bonus = parseFloat(bonusRate);

    // Annual Survival Benefit is a percentage of Sum Assured
    const annualSurvivalBenefit = SA * 0.08; // Assuming 8% of Sum Assured

    // Total Premium Paid
    const totalPremiumPaid = (SA / PTerm) * 12 * PTerm;

    // Calculate Maturity Benefit (Sum Assured + Bonuses)
    const totalBonus = SA * (bonus / 1000) * PT;
    const maturityBenefit = SA + totalBonus;

    // Death Benefit (Higher of Sum Assured + Bonuses or 105% of all premiums paid)
    const deathBenefit = Math.max(SA + totalBonus, totalPremiumPaid * 1.05);

    // Calculate Bonus Projections
    const bonusProjections = [];
    let accumulatedBonus = 0;
    for (let i = 1; i <= PT; i++) {
      accumulatedBonus += SA * (bonus / 1000);
      bonusProjections.push({
        year: i,
        bonus: accumulatedBonus.toFixed(2),
      });
    }

    setCalculationData({
      annualSurvivalBenefit: annualSurvivalBenefit.toFixed(2),
      totalPremiumPaid: totalPremiumPaid.toFixed(2),
      maturityBenefit: maturityBenefit.toFixed(2),
      deathBenefit: deathBenefit.toFixed(2),
      bonusProjections,
    });

    setValidationError(''); // Clear validation error if calculation succeeds
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateJeevanUmang();
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Annual Benefit Amount': calculationData.annualSurvivalBenefit },
      { 'Total Premium:': calculationData.totalPremiumPaid },
      { 'Maturity Benefit': calculationData.maturityBenefit },
      { 'Death Benefit': calculationData.deathBenefit },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'Post Office PPF Calculator Data');
    XLSX.writeFile(wb, 'Post_Office_PPF_Calculator.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
   <Layout pageTitle="LIC Jeevan Umang Calculator | LICCalculators"
   metaDescription="Calculate LIC Jeevan Umang premiums and benefits with our online calculator."
   metaKeywords="LIC Jeevan Umang calculator, Jeevan Umang premiums, LIC policy calculator"
   canonicalUrl="https://liccalculators.com/lic-jeevan-umang-calculator"
   robotsContent="index, follow"
   ogTitle="LIC Jeevan Umang Calculator | LICCalculators"
   ogDescription="Use our LIC Jeevan Umang Calculator to estimate premiums and benefits for your policy."
   ogUrl="https://liccalculators.com/lic-jeevan-umang-calculator"
   ogImage="https://liccalculators.com/img/new_logo.png"
   schemaData={{
     "@context": "https://schema.org",
     "@type": "WebPage",
     "url": "https://liccalculators.com/lic-jeevan-umang-calculator",
     "name": "LIC Jeevan Umang Calculator",
     "description": "Calculate LIC Jeevan Umang premiums and benefits with our online calculator.",
     "publisher": {
       "@type": "Organization",
       "name": "LICCalculators",
       "logo": {
         "@type": "ImageObject",
         "url": "https://liccalculators.com/img/new_logo.png"
       }
     }
   }}>
   <div className='container-fluid'>
    <div className='row'>
      <div className='col-md-1'>

      </div>
      <div className='col-md-8 main-section'>
         <div className='container'>
         <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
        <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Lic Jeevan Umang Calculator</li>
      </ol>
        </nav>
        <form onSubmit={handleSubmit}>
        <h1 className='text-center'>Lic Jeevan Umang Calculator</h1>
        <p className='text-center'>Calculate returns on your Investment Plan.</p>
          <div className='container text-center'>
            <div className='row'>
              <div className='col-md-12'>
              <label htmlFor='sum'  className='form-label label'><b>Sum Assured (INR):</b> <span className='text-danger'>*</span> </label>
          <input
            type="number"
            className='form-control'
            id='investmentAmount'
            value={sumAssured}
            onChange={(e) => setSumAssured(e.target.value)}
            placeholder='Enter Sum Assured Amount'
            required
          />
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
              <label htmlFor='Policy' className='form-label label'><b>Policy Term (Years):</b> <span className='text-danger'>*</span> </label>
          <input
            type="number"
            className='form-control'
            id='annualInterestRate'
            value={policyTerm}
            onChange={(e) => setPolicyTerm(e.target.value)}
            placeholder='Enter Policy Term'
            required
          />
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
              <label htmlFor='Premium' className='form-label label'><b>Premium Payment Term (Years) :</b><span className='text-danger'>*</span> </label>
          <input
            type="number"
            className='form-control'
            value={premiumTerm}
            onChange={(e) => setPremiumTerm(e.target.value)}
            id='investmentPeriod'
            placeholder='Enter Policy Term'
            required
          />
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
              <label  htmlFor='age'  className='form-label label'><b>Age (Years):</b> <span className='text-danger'>*</span> </label>
          <input
            type="number"
            className='form-control'
            id='investmentPeriod'
            value={age}
            onChange={(e) => setAge(e.target.value)}
            placeholder='Enter Age'
            required
          /> 
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
              <label htmlFor='bonus' className='clas
              form-label label'><b>Bonus Rate (per 1000 INR):</b>  <span className='text-danger'>*</span></label>
          <input
            type="number"
            className='form-control'
            id='annualInterestRate'
            value={bonusRate}
            onChange={(e) => setBonusRate(e.target.value)}
            required
          />
              </div>
            </div>
          </div>
          {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
          
         <div className='row input-row'>
          <div className='col-md-12'>
          <button type="submit" id='annualInterestRate' className='btn btn-success'>Calculate</button>
          </div>
         </div>
        </form>
        {calculationData && (
        <div className='container'>
        <hr></hr>
          <h5>Summary :</h5> <br></br>
          <div className='row'>
            <div className='table-responsive'>
            <table className='table'>
            <tbody>
              <tr>
                <th scope='col'>Annual Survival Benefit:</th>
                <td>{formatCurrency(calculationData.annualSurvivalBenefit)}</td>
              </tr>
              <tr>
                <th scope='col'>Total Premium Paid:</th>
                <td>{formatCurrency(calculationData.totalPremiumPaid)}</td>
              </tr>
              <tr>
                <th scope='col'>Maturity Benefit:</th>
                <td>{formatCurrency(calculationData.maturityBenefit)}</td>
              </tr>
              <tr>
                <th scope='col'>Death Benefit:</th>
                <td>{formatCurrency(calculationData.deathBenefit)}</td>
              </tr>
            </tbody>
          </table>              
            </div>
            <button className="btn btn-success" id='annualInterestRate' onClick={exportToExcel} >Export to Excel</button>
          </div>

          <div className='container'>
            <div className='row'>
            <h5 className='text-center' style={{marginTop:'20px'}}>Bonus Projections</h5>
            <Bar
            data={{
              labels: calculationData.bonusProjections.map(b => `Year ${b.year}`),
              datasets: [
                {
                  label: 'Projected Bonus (INR)',
                  data: calculationData.bonusProjections.map(b => b.bonus),
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    callback: function (value) {
                      return '₹ ' + value;
                    },
                  },
                },
              },
            }}
          />
            </div>
          </div>
        
        </div>
      )}
      <div className='container'>
  <div className='row' style={{marginTop:"50px"}}>
    <hr></hr>
    <h3 className='text-center'> About LIC Jeevan Umang Calculator</h3>
    <hr></hr>
    <p style={{fontFamily:'sans-serif'}}>The LIC Jeevan Umang Calculator is an online tool specifically designed to help users calculate various aspects of the LIC Jeevan Umang Policy, a popular life insurance policy offered by the Life Insurance Corporation of India (LIC). This policy provides a unique combination of income and protection, offering coverage up to 100 years of age, along with annual survival benefits after the premium payment term ends.</p>

    <h5>Key Features of the LIC Jeevan Umang Calculator:</h5>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li><b>Premium Calculation:</b> Based on user inputs such as Sum Assured, Age of the Insured, and Policy Term, the calculator helps users determine the premium amount they need to pay for the policy.</li>
        <li><b>Survival Benefits:</b> The calculator estimates the annual survival benefits that the policyholder will start receiving after the premium payment term ends and continue receiving until the age of 100.</li>
        <li><b>Maturity Benefits:</b> It calculates the Sum Assured payable at maturity or the total amount the nominee will receive in the event of the policyholder's death during the policy term.</li>
        <li><b>Death Benefits:</b> In case of unfortunate death, the calculator provides an estimate of the death benefit payable to the policyholder’s nominee, ensuring the family is financially secure.</li>
        <li><b>Bonus Calculation:</b> The LIC Jeevan Umang policy also offers bonuses, which are added to the sum assured every year. The calculator can factor in these bonuses to give a clearer picture of the policy's benefits.</li>
      </ul>
    </div>

    <h5>Input Parameters for the LIC Jeevan Umang Calculator:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Sum Assured:</b> The guaranteed amount the policyholder or nominee will receive upon maturity or death.</li>
        <li><b>Age of the Insured:</b> The age of the policyholder affects the premium amount and benefits.</li>
        <li><b>Policy Term:</b> The number of years the policy will remain active, which impacts the premiums and maturity benefits.</li>
        <li><b>Premium Payment Term:</b> The number of years for which premiums must be paid. Typically, these terms are flexible and can range from 15, 20, or 25 years.</li>
        <li><b>Bonus Rate:</b> The annual bonuses declared by LIC, which increase the overall maturity and death benefits. The calculator includes this rate to provide more accurate results.</li>
      </ul>
    </div>

    <h5>Benefits of Using the LIC Jeevan Umang Calculator:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Easy Planning:</b> It simplifies financial planning by giving a clear estimate of the premium and the returns over time, helping policyholders make sound decisions.</li>
        <li><b>Accurate Insights:</b> It provides precise calculations based on user input, giving users a clear projection of the benefits at various stages, such as maturity, death, and survival payouts.</li>
        <li><b>Convenience:</b> Users can quickly compare multiple policy scenarios by adjusting variables like sum assured and premium term, ensuring they pick the best option for their financial goals.</li>
        <li><b>Long-Term Planning:</b> The LIC Jeevan Umang Calculator helps users assess the long-term survival benefits and life coverage, giving them confidence in their financial security for their lifetime and beyond.</li>
        <li><b>Bonus Projections:</b> The calculator also includes bonus estimations, providing a full picture of how the policy can grow over time through regular bonuses.</li>
      </ul>
    </div>

    <h5>How the LIC Jeevan Umang Policy Works:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <p>The LIC Jeevan Umang is a whole-life insurance plan, providing coverage up to 100 years of age, with regular annual payouts starting after the premium payment term ends. This plan offers:</p>
      <ul>
        <li><b>Survival Benefits:</b> After the premium payment term is complete, policyholders receive annual payouts, typically a percentage of the sum assured, every year until they reach 100 years of age.</li>
        <li><b>Maturity Benefit:</b> Upon reaching the age of 100, the policyholder receives the sum assured plus accumulated bonuses.</li>
        <li><b>Death Benefit:</b> In the unfortunate event of the policyholder’s death during the policy term, the nominee receives the sum assured along with bonuses accrued until that point.</li>
      </ul>
    </div>

    <h5>Steps to Use the LIC Jeevan Umang Calculator:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li>Enter the desired sum assured amount.</li>
        <li>Input the policyholder's age and the chosen policy term.</li>
        <li>Specify the premium payment term and the bonus rate (if known).</li>
        <li>Click "Calculate" to get the estimated premium, survival benefits, and maturity amounts.</li>
      </ul>
    </div>

    <p style={{fontFamily:'sans-serif'}}>The LIC Jeevan Umang Calculator is an invaluable tool for policyholders looking to invest in a plan that offers both long-term income and life insurance coverage, helping them understand the potential benefits and plan their finances accordingly.</p>
  </div>
</div>

         </div>
      </div>
      <div className='col-md-3'>
        <Sidebar/>
      </div>
    </div>
   </div>



   </Layout>
  );
};

export default JeevanUmangCalculator;
