import React, { useState , useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // To automatically register chart components
import { Link } from 'react-router-dom';
import Layout from '../Componets/Layout';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar';


const LicPremiumCalculator = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [sumAssured, setSumAssured] = useState('');
  const [age, setAge] = useState('');
  const [policyTerm, setPolicyTerm] = useState('');
  const [premium, setPremium] = useState(null);
  const [chartData, setChartData] = useState({});
  const [errors, setErrors] = useState({}); // State for validation errors

  // Validation function
  const validateForm = () => {
    let formErrors = {};
    if (!sumAssured || sumAssured <= 0) {
      formErrors.sumAssured = "Please enter a valid Sum Assured greater than 0.";
    }
    if (!age || age <= 0 || age > 100) {
      formErrors.age = "Please enter a valid Age (1-100).";
    }
    if (!policyTerm || policyTerm <= 0) {
      formErrors.policyTerm = "Please enter a valid Policy Term greater than 0.";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Function to calculate premium (dummy formula)
  const calculatePremium = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const monthlyPremium = (sumAssured / policyTerm) * (1 + (age / 100));
      const totalAmount = monthlyPremium * policyTerm * 12;
      const totalInterest = totalAmount - sumAssured;
      setPremium({
        monthlyPremium: monthlyPremium.toFixed(2),
        totalPrincipal: sumAssured,
        totalInterest: totalInterest.toFixed(2),
        totalAmount: totalAmount.toFixed(2),
      });
      generateChart(monthlyPremium, totalInterest, totalAmount);
    }
  };

  // Generate chart data for Bar Chart
  const generateChart = (monthlyPremium, totalInterest, totalAmount) => {
    setChartData({
      labels: ['Monthly Premium (INR)', 'Total Interest (INR)', 'Total Amount (INR)'],
      datasets: [
        {
          label: 'LIC Premium Breakdown',
          data: [monthlyPremium, totalInterest, totalAmount],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          borderWidth: 1,
        },
      ],
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Total Amount': premium.totalAmount },
      { 'Total Interest	': premium.totalInterest },
      { 'Total Principal': premium.totalPrincipal },
      { 'Monthly Premium ': premium.monthlyPremium },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'Post Office RD Calculator Data');
    XLSX.writeFile(wb, 'Post_Office_RD_Calculator.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout  pageTitle="LIC Premium Calculator | LICCalculators"
    metaDescription="Calculate your LIC policy premiums using our LIC Premium Calculator. Get accurate results for various LIC policies."
    metaKeywords="LIC premium calculator, LIC policy premiums, calculate LIC premiums"
    canonicalUrl="https://liccalculators.com/lic-premium-calculator"
    robotsContent="index, follow"
    ogTitle="LIC Premium Calculator | LICCalculators"
    ogDescription="Estimate your LIC policy premiums accurately with our LIC Premium Calculator."
    ogUrl="https://liccalculators.com/lic-premium-calculator"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/lic-premium-calculator",
      "name": "LIC Premium Calculator",
      "description": "Calculate your LIC policy premiums using our LIC Premium Calculator.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}>
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'>
        </div>
        <div className='col-md-8 main-section'>
        <div className='container'>
        <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Lic Premium Calculator</li>
                </ol>
        </nav>
        <form>
          <h1 className='text-center'>Lic Premium Calculator</h1>
          <p className='text-center'>Simplifying Your Premium Calculations.</p>
          <div className='container text-center'>
            <div className='row'>
              <div className='col-md-12'>
                <label htmlFor='sum'  className='form-label label'><b>Sum Assured (INR):</b><span className='text-danger'>*</span></label>
                <input
                  type="number"
                  value={sumAssured}
                  className='form-control'
                  id="investmentAmount" 
                  placeholder='20000'
                  onChange={(e) => setSumAssured(e.target.value)}
                  required
                />
                {errors.sumAssured && <span className='text-danger'>{errors.sumAssured}</span>}
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <label htmlFor='Age' className='form-label label'><b>Age of Insured (Years):</b><span className='text-danger'>*</span></label>
                <input
                  type="number"
                  className='form-control'
                  id='investmentPeriod'
                  value={age}

                  onChange={(e) => setAge(e.target.value)}
                  placeholder='6'
                  required
                />
                {errors.age && <span className='text-danger'>{errors.age}</span>}
              </div>
            </div>
            <div className='row input-row'>
              <div className='col-md-12'>
                <label htmlFor='Policy' className='form-label label'><b>Policy Term (Years):</b><span className='text-danger'>*</span></label>
                <input
                  type="number"
                  value={policyTerm}
                  className='form-control'
                  id='annualInterestRate'
                  onChange={(e) => setPolicyTerm(e.target.value)}
                  placeholder='3'
                  required
                />
                {errors.policyTerm && <span className='text-danger'>{errors.policyTerm}</span>}
              </div>
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
              <button type='submit' id="annualInterestRate" className='btn btn-success' onClick={calculatePremium}>
                Calculate Premium
              </button>
            </div>
          </div>
        </form>

      {premium && (
        <div className='container'>
        <hr></hr>
        <h5>Summary :</h5> <br></br>
        <div className='row'>
          <div className='table-responsive'>
            <table className='table'>
            <tbody>
              <tr>
                <th scope='col'>Monthly Premium (INR)</th>
                <td>{formatCurrency(premium.monthlyPremium)}</td>

              </tr>
              <tr>
                <th scope='col'>Total Principal (INR)</th>
                <td>{formatCurrency(premium.totalPrincipal)}</td>
              </tr>
              <tr>
                <th scope='col'>Total Interest (INR)</th>
                <td>{formatCurrency(premium.totalInterest)}</td>
              </tr>
              <tr>
                <th scope='col'>Total Amount (INR)</th>
                <td>{formatCurrency(premium.totalAmount)}</td>
              </tr>
            </tbody>

            </table>
          </div>
          <button className="btn btn-success" id='exportButton' onClick={exportToExcel} >Export to Excel</button>
        </div>
          <div className='container'>
            <div className='row'>
            <h5 className='text-center' style={{marginTop:'20px'}}>Data In Graph Format :</h5>
            <Bar data={chartData} />
            </div>
          </div>
        </div>
      )}
      <div className='container'>
      <div className='row' style={{marginTop:"50px"}}>
        <hr ></hr>
        <h3 className='text-center text-black'>About Lic Premium Calculator </h3>
        <hr></hr>
        <p style={{fontFamily:"sans-serif"}}>The LIC Premium Calculator is a tool designed to help individuals calculate the premium amounts they need to pay for their Life Insurance Corporation (LIC) policies. It simplifies the process of determining the cost of various LIC plans based on inputs like the sum assured, age, policy term, and rider options. This tool is particularly useful for those planning their financial commitments and seeking life insurance policies tailored to their needs.</p>
        <h4>Key Features of the LIC Premium Calculator:</h4>
        <div style={{fontFamily:"sans-serif"}}>
          <ul>
            <li><b>Sum Assured: </b>Calculate the premium based on the amount of coverage (sum assured) selected.</li>
            <li><b>Policy Term:</b> Allows users to input the number of years they wish to hold the policy.</li>
            <li><b>Age of Insured:</b> The age of the policyholder is an important factor in determining the premium.</li>
          </ul>
        </div>
        <h5>Benefits of Using the LIC Premium Calculator:</h5>
        <div style={{fontFamily:'sans-serif'}}>
          <ul>
            <li><b>Time-Saving:</b> Get an instant estimate of the premiums without visiting an LIC office.</li>
            <li><b>Easy Comparison:</b> Compare premiums across various LIC plans to find the best option.</li>
            <li><b>Budget Planning:</b> Helps users plan their finances effectively by understanding premium obligations.</li>
            <li><b>Transparency:</b> Gives clear insights into premium costs, making it easier to make an informed decision.</li>
          </ul>
        </div>
        <p style={{fontFamily:"sans-serif"}}>The LIC Premium Calculator is an essential tool for anyone considering an LIC policy, ensuring they have a clear understanding of the cost before making a commitment.</p>
      </div>
     </div>
    </div>
    </div>
    <div className='col-md-3'>
      <Sidebar/>
    </div>
  </div>
</div>
</Layout>
  );
};

export default LicPremiumCalculator;
