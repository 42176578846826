// src/JeevanSaralCalculator.js
import React, { useState,useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar';
// import './JeevanSaralCalculator.css'; // For styling

ChartJS.register(ArcElement, Tooltip, Legend);

const JeevanSaralMaturityCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [age, setAge] = useState('');
  const [premium, setPremium] = useState('');
  const [policyTerm, setPolicyTerm] = useState('');
  const [sumAssured, setSumAssured] = useState('');
  const [result, setResult] = useState(null);

  const calculateMaturityAmount = () => {
    // Dummy calculation logic
    // Replace with actual LIC Jeevan Saral calculation logic
    const maturityAmount = (parseFloat(premium) * parseInt(policyTerm)) + parseFloat(sumAssured);
    const data = {
      labels: ['Premium Paid', 'Sum Assured', 'Maturity Amount'],
      datasets: [{
        label: 'Breakdown of Maturity Amount',
        data: [parseFloat(premium) * parseInt(policyTerm), parseFloat(sumAssured), maturityAmount],
        backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
        borderWidth: 1
      }]
    };
    setResult({ maturityAmount, data });
  };

   
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Maturity Amount': result.maturityAmount.toFixed(2) },
     
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'Post Office PPF Calculator Data');
    XLSX.writeFile(wb, 'Post_Office_PPF_Calculator.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
   <Layout pageTitle="LIC Jeevan Saral Maturity Calculator | LICCalculators"
   metaDescription="Use the LIC Jeevan Saral Maturity Calculator to calculate the maturity amount for your LIC Jeevan Saral policy."
   metaKeywords="LIC Jeevan Saral maturity calculator, LIC Jeevan Saral calculator, LIC policy maturity"
   canonicalUrl="https://liccalculators.com/lic-jeevan-saral-maturity-calculator"
   robotsContent="index, follow"
   ogTitle="LIC Jeevan Saral Maturity Calculator | LICCalculators"
   ogDescription="Calculate the maturity amount of your LIC Jeevan Saral policy using the LIC Jeevan Saral Maturity Calculator."
   ogUrl="https://liccalculators.com/lic-jeevan-saral-maturity-calculator"
   ogImage="https://liccalculators.com/img/new_logo.png"
   schemaData={{
     "@context": "https://schema.org",
     "@type": "WebPage",
     "url": "https://liccalculators.com/lic-jeevan-saral-maturity-calculator",
     "name": "LIC Jeevan Saral Maturity Calculator",
     "description": "Calculate the maturity amount of your LIC Jeevan Saral policy using the LIC Jeevan Saral Maturity Calculator.",
     "publisher": {
       "@type": "Organization",
       "name": "LICCalculators",
       "logo": {
         "@type": "ImageObject",
         "url": "https://liccalculators.com/img/new_logo.png"
       }
     }
   }}>

   <div className='container-fluid'>
    <div className='row'>
      <div className='col-md-1'>

      </div>
      <div className='col-md-8 main-section'>
      <div className='container'>
        <div className='bred'>
        <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
        <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Post Office PPF Calculator</li>
      </ol>
        </nav>
        </div>
        <h1 className='text-center'>
        Lic Jeevan Saral Maturity Calculator
        </h1>
        <p className='text-center'>Calculate returns on your Investment Plan.</p>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
            <label htmlFor='sum' className='form-label label'><b>Sum Assured :</b><span className='text-danger'>*</span></label>
           <input type="number"
           className='form-control'
           id='investmentAmount'
            value={sumAssured} onChange={(e) => setSumAssured(e.target.value)} 
              placeholder='Enter Sum of Assured'
              required
            />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='Age' className='form-label label'><b>Age of Insured:</b><span className='text-danger'>*</span></label>
            <input 
            type="number" 
            className='form-control'
            id='annualInterestRate'
            value={age}
             onChange={(e) => setAge(e.target.value)} 
             placeholder='Enter Age of Insured'
              required
             />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='MonthlyPremium' className='form-label label'><b>Monthly Premium:</b><span className='text-danger'>*</span></label>
            <input
             type="number"
             className='form-control'
             id='investmentPeriod'
              value={premium}
               onChange={(e) => setPremium(e.target.value)}
               placeholder='Enter Monthly Premium'
                />
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='policy' className='form-label label'><b>Policy Term (years):</b> <span className='text-danger'>*</span></label>
            <input 
            type="number"
            className='form-control'
            id='annualInterestRate'
             value={policyTerm} onChange={(e) => setPolicyTerm(e.target.value)} 
             placeholder='Enter Policy Terms'

             />
            </div>
          </div>
        </div>
        <div className='row input-row'>
          <div className='col-md-12'>
          <button className='btn btn-success'  id='annualInterestRate' onClick={calculateMaturityAmount}>Calculate</button>
          </div>
        </div>
        {result && (
        <div className='container'>
        <hr></hr>
          <h5>Summary :</h5> <br></br>
          <div className='row'>
            <div className='table-responsive'>
            <table className='table'>
          <tbody>
            <tr>
              <th scope='col'>Maturity Amount</th>
              <td>{formatCurrency(result.maturityAmount.toFixed(2))}</td>
            </tr>
          </tbody>
        </table>
            </div>
            <button className="btn btn-success" onClick={exportToExcel} >Export to Excel</button>
          </div>
          <div className='container' >
            <div className='row'>
            <h5 className='text-center' style={{marginTop:'20px'}}>Data In Graph Formate :</h5>
            <Bar data={result.data}  />
           
            </div>
          </div>
        </div>
      )}

      <div className='container'>
  <div className='row' style={{marginTop:"50px"}}>
    <hr></hr>
    <h3 className='text-center text-black'>About LIC Jeevan Saral Maturity Calculator</h3>
    <hr></hr>
    <p style={{fontFamily:"sans-serif"}}>The LIC Jeevan Saral Maturity Calculator is an online tool designed to help policyholders calculate the maturity benefits of the LIC Jeevan Saral policy, a unique life insurance plan that provides both flexibility and guaranteed returns. This plan is ideal for individuals looking for a combination of protection and savings with the added convenience of flexible premium payments.</p>
    <h5>Key Features of the LIC Jeevan Saral Maturity Calculator:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Maturity Sum Calculation:</b> Based on the premium amount, age of the policyholder, policy tenure, and the monthly premium, the calculator estimates the maturity sum. This includes both the Guaranteed Sum Assured and the Loyalty Additions declared by LIC over the policy term.</li>
        <li><b>Guaranteed Returns:</b> The calculator provides an estimate of the Guaranteed Maturity Amount, which is determined by LIC’s actuarial tables. This amount is predetermined at the time of policy purchase and is not affected by market fluctuations.</li>
        <li><b>Death Benefits:</b> In case of the policyholder’s death during the policy term, the calculator can also estimate the Death Benefit, which includes 250 times the monthly premium along with the return of premiums paid, excluding the first-year premium and rider premiums.</li>
      </ul>
    </div>
    
    <h5>Input Parameters:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Monthly Premium Amount:</b> The amount paid each month by the policyholder, which will affect the maturity and death benefits.</li>
        <li><b>Policy Tenure:</b> The length of the policy term, which can range from 10 to 35 years. The longer the tenure, the higher the returns due to accumulation of bonuses.</li>
        <li><b>Age of Policyholder:</b> The age at which the policy is purchased affects the premium and maturity value.</li>
        <li><b>Loyalty Additions:</b> These are additional benefits paid out at maturity, based on LIC’s performance, and are factored into the calculator.</li>
      </ul>
    </div>

    <h5>Benefits of Using the LIC Jeevan Saral Maturity Calculator:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Accurate Estimates:</b> By inputting key details such as policy tenure, age, and premium amount, users can get a clear estimate of the maturity benefits they will receive, making financial planning easier.</li>
        <li><b>Comparison of Different Scenarios:</b> Users can try out different premium amounts and policy terms to find the best combination that suits their financial goals.</li>
        <li><b>Transparency:</b> The calculator breaks down the maturity sum into Guaranteed Benefits and Loyalty Additions, providing full transparency about the returns.</li>
        <li><b>Ease of Use:</b> The calculator is designed to be user-friendly, allowing policyholders to calculate their expected maturity values in just a few clicks.</li>
      </ul>
    </div>
    
    <h5>How to Use the LIC Jeevan Saral Maturity Calculator:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li>Enter your monthly premium amount.</li>
        <li>Select the policy term and your current age.</li>
        <li>The calculator will automatically generate the estimated maturity amount, including the Guaranteed Sum Assured and Loyalty Additions.</li>
      </ul>
    </div>

    <p style={{fontFamily:'sans-serif'}}>The LIC Jeevan Saral Maturity Calculator is a valuable tool for policyholders seeking to maximize their returns from the LIC Jeevan Saral policy and plan for their future financial needs with confidence.</p>
  </div>
</div>

      </div>

      </div>
      <div className='col-md-3'>
       <Sidebar/>
      </div>
    </div>
   </div>

    
   </Layout>
  );
};

export default JeevanSaralMaturityCalculator;
