import React, { useState , useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar';

const LicMaturityCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [sumAssured, setSumAssured] = useState('');
  const [policyTerm, setPolicyTerm] = useState('');
  const [annualBonusRate, setAnnualBonusRate] = useState('');
  const [finalBonus, setFinalBonus] = useState('');
  const [maturityAmount, setMaturityAmount] = useState(null);
  const [errors, setErrors] = useState({});
  const [chartData, setChartData] = useState({});

  const validateInputs = () => {
    const newErrors = {};

    if (!sumAssured || parseFloat(sumAssured) <= 0) {
      newErrors.sumAssured = 'Sum Assured must be greater than 0.';
    }
    if (!policyTerm || parseFloat(policyTerm) <= 0) {
      newErrors.policyTerm = 'Policy Term must be greater than 0.';
    }
    if (!annualBonusRate || parseFloat(annualBonusRate) <= 0) {
      newErrors.annualBonusRate = 'Annual Bonus Rate must be greater than 0.';
    }
    if (finalBonus && parseFloat(finalBonus) < 0) {
      newErrors.finalBonus = 'Final Bonus cannot be negative.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Function to calculate maturity amount
    // Function to calculate maturity amount
    const calculateMaturityAmount = (e) => {
      e.preventDefault();
      if (validateInputs()) {
        const annualBonus = (sumAssured * annualBonusRate * policyTerm) / 100;
        const totalMaturity = parseFloat(sumAssured) + annualBonus + parseFloat(finalBonus || 0);
  
        setMaturityAmount({
          sumAssured: parseFloat(sumAssured).toFixed(2),
          totalBonus: annualBonus.toFixed(2),
          finalBonus: parseFloat(finalBonus || 0).toFixed(2),
          totalMaturity: totalMaturity.toFixed(2),
        });
  
        generateChart(parseFloat(sumAssured), annualBonus, finalBonus, totalMaturity);
      }
    };

  // Generate chart data
  const generateChart = (sumAssured, totalBonus, finalBonus, totalMaturity) => {
    setChartData({
      labels: ['Sum Assured (INR)', 'Bonus (INR)', 'Final Bonus (INR)', 'Total Maturity (INR)'],
      datasets: [
        {
          label: 'LIC Maturity Breakdown',
          data: [sumAssured, totalBonus, finalBonus, totalMaturity],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
          borderWidth: 1,
        },
      ],
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { ' Sum Assured ': maturityAmount.sumAssured },
      { 'Bonus': maturityAmount.totalBonus },
      { 'Final Bonus	': maturityAmount.finalBonus},
      { 'Total Maturity Amount': maturityAmount.totalMaturity }
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'Lic Maturity');
    XLSX.writeFile(wb, 'Lic_Maturity.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout  pageTitle="LIC Maturity Calculator | LICCalculators"
    metaDescription="Calculate your LIC policy maturity amount using our LIC Maturity Calculator. Get accurate projections for your LIC policy maturity."
    metaKeywords="LIC maturity calculator, LIC policy maturity, LIC policy calculator"
    canonicalUrl="https://liccalculators.com/lic-maturity-calculator"
    robotsContent="index, follow"
    ogTitle="LIC Maturity Calculator | LICCalculators"
    ogDescription="Accurately calculate your LIC policy maturity amount using our LIC Maturity Calculator."
    ogUrl="https://liccalculators.com/lic-maturity-calculator"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/lic-maturity-calculator",
      "name": "LIC Maturity Calculator",
      "description": "Calculate your LIC policy maturity amount using the LIC Maturity Calculator.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/assets/img/new_logo.png"
        }
      }
    }} >
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'>

        </div>
        <div className='col-md-8 main-section'>
          <div className='container'>
          <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
                    <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Lic Maturity Calculator</li>
                </ol>
                    </nav>
                    <form>
                    <h1 className='text-center'>Lic Maturity Calculator</h1>
                    <p className='text-center'>Calculate returns on your Investment Plan.</p>
                    <div className='container text-center'>
                      <div className='row'>
                        <div className='col-md-12'>
                        <label htmlFor='Sum' className='form-label label'><b>Sum Assured (INR):</b><span className='text-danger'>*</span></label>
                          <input
                            type="number"
                            className='form-control'
                            id='investmentAmount'
                            value={sumAssured}
                            onChange={(e) => setSumAssured(e.target.value)}
                            placeholder='Enter Sum Assured Amount'
                          />
                            {errors.sumAssured && <span className="text-danger">{errors.sumAssured}</span>}
                        </div>
                      </div>
                      <div className='row input-row'>
                        <div className='col-md-12'>
                        <label htmlFor='Policy'><b>Policy Term (Years):</b><span className='text-danger'>*</span></label>
                        <input
                          type="number"
                          className='form-control'
                          id='investmentPeriod'
                          value={policyTerm}
                          onChange={(e) => setPolicyTerm(e.target.value)}
                          placeholder='Enter Policy Term'
                          required
                        />
                          {errors.policyTerm && <span className="text-danger">{errors.policyTerm}</span>}
                        </div>
                      </div>
                      <div className='row input-row'>
                        <div className='col-md-12'>
                        <label htmlFor='Annual' className='form-label label'><b>Annual Bonus Rate (%):</b><span className='text-danger'>*</span></label>
                        <input
                          type="number"
                          className='form-control'
                          id='annualInterestRate'
                          value={annualBonusRate}
                          onChange={(e) => setAnnualBonusRate(e.target.value)}
                          placeholder='Enter Annual Bonus Rate'
                        />
                         {errors.annualBonusRate && <span className="text-danger">{errors.annualBonusRate}</span>}
                        </div>
                      </div>
                      <div className='row input-row'>
                        <div className='col-md-12'>
                        <label htmlFor='finalBonus' className='form-label label'><b>Final Additional Bonus (INR):</b><span className='text-danger'>*</span></label>
                        <input
                          type="number"
                          className='form-control'
                          id='annualInterestRate'
                          value={finalBonus}
                          onChange={(e) => setFinalBonus(e.target.value)}
                          placeholder='Enter Additional Bonus'
                          required
                        />
                        {errors.finalBonus && <span className="text-danger">{errors.finalBonus}</span>}
                        </div>
                      </div>
                    </div>
                    <div className='row input-row'>
                      <div className='col-md-12'>
                      <button type='submit' id='annualInterestRate'  className="btn btn-success"onClick={calculateMaturityAmount}>Calculate Maturity Amount</button>
                      </div>
                    </div>
                    </form>

                    {maturityAmount && (
                <div className='container'>
                <hr></hr>

          <h5>Maturity Summary</h5> <br></br>
          <div className='row'>
            <div className='table-responsive'>
              <table className='table'>
                <tbody>
                  <tr>
                    <th scope='col'>Sum Assured</th>
                    <td>{formatCurrency(maturityAmount.sumAssured)}</td>
                  </tr>
                  <tr>
                    <th scope='col'>Bonus</th>
                    <td>{formatCurrency(maturityAmount.totalBonus)}</td>
                  </tr>
                  <tr>
                    <th scope='col'>Final Bonus</th>
                    <td>{formatCurrency(maturityAmount.finalBonus)}</td>
                  </tr>
                 <tr>
                  <th scope='col'>Total Maturity Amount</th>
                  <td>{formatCurrency(maturityAmount.totalMaturity)}</td>
                 </tr>
                </tbody>
              </table>
            </div>
            <button className="btn btn-success" id='annualInterestRate' onClick={exportToExcel} >Export to Excel</button>
          </div>
          <div className='container'>
            <div className='row'>
            <h5 className='text-center' style={{marginTop:'20px'}}>Data In Graph Formate :</h5>
            <Bar data={chartData} />
            </div>
          </div>
          
         
          
        </div>
      )}
      <div className='container'>
  <div className='row' style={{marginTop:"50px"}}>
    <hr></hr>
    <h3 className='text-center text-black'>About LIC Maturity Calculator</h3>
    <hr></hr>
    <p style={{fontFamily:"sans-serif"}}>The LIC Maturity Calculator is a financial tool designed to help policyholders estimate the maturity amount they will receive at the end of their LIC (Life Insurance Corporation of India) policy term. Maturity benefits typically include the Sum Assured, Bonuses, and any additional final bonuses, depending on the policy type. This calculator simplifies the complex calculation by taking key inputs from the user and providing a detailed breakdown of the expected returns.</p>
    
    <h4>Key Features:</h4>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li><b>Sum Assured Input:</b> Enter the guaranteed amount provided by LIC upon maturity. This is the base amount on which benefits are calculated.</li>
        <li><b>Policy Term:</b> Input the number of years the policy will run. This determines the duration for which the bonuses are accumulated.</li>
        <li><b>Annual Bonus Rate:</b> Input the estimated bonus rate provided by LIC. This percentage is added annually to the Sum Assured, reflecting the policy's growth over time.</li>
        <li><b>Final Bonus (Optional):</b> Some LIC policies offer a one-time final bonus at the end of the policy term. This bonus can be included for a more accurate maturity estimate.</li>
      </ul>
    </div>
    
    <h4>How It Works:</h4>
    <p style={{fontFamily:"sans-serif"}}>The calculator computes the Total Maturity Amount using the following formula:</p>
    <div style={{fontFamily:"sans-serif"}}>
      <p>Total Maturity Amount = Sum Assured + (Annual Bonus Rate × Policy Term × Sum Assured) + Final Bonus (if any).</p>
    </div>
    
    <h5>Benefits:</h5>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li><b>Accurate Estimates:</b> Provides policyholders with a precise estimate of their maturity benefits, allowing for better financial planning.</li>
        <li><b>Simplifies Calculation:</b> Automates complex calculations related to bonuses and final payouts, saving users time and effort.</li>
        <li><b>Graphical Representation:</b> Visualizes maturity details in both table format and a bar chart, making it easier to understand and analyze the returns.</li>
        <li><b>Excel Export:</b> Allows users to export detailed maturity data to an Excel file for easy reference and further analysis.</li>
        <li><b>Customizable Inputs:</b> Users can adjust inputs based on different scenarios, such as varying bonus rates or policy terms, to see how these changes affect the maturity amount.</li>
        <li><b>Easy-to-Use Interface:</b> The calculator features a user-friendly interface that makes it accessible even to those with minimal financial knowledge.</li>
      </ul>
    </div>
    
    <h5>Why Use the LIC Maturity Calculator?</h5>
    <div style={{fontFamily:"sans-serif"}}>
      <ul>
        <li><b>Future Planning:</b> Helps policyholders plan their financial future by providing a clear estimate of the benefits they will receive upon policy maturity.</li>
        <li><b>Financial Goal Setting:</b> Assists in setting and adjusting financial goals based on expected maturity amounts, ensuring that policyholders are on track to meet their financial objectives.</li>
        <li><b>Investment Decisions:</b> Provides valuable insights for making informed decisions about continuing or adjusting investments in LIC policies.</li>
        <li><b>Transparency:</b> Offers transparency in understanding how different factors, such as bonus rates and policy terms, impact the total maturity amount.</li>
      </ul>
    </div>

    <h5>How to Use the LIC Maturity Calculator:</h5>
    <div style={{fontFamily:"sans-serif"}}>
      <ol>
        <li><b>Enter Sum Assured:</b> Input the guaranteed sum assured amount provided by your LIC policy.</li>
        <li><b>Specify Policy Term:</b> Enter the total duration of the policy in years.</li>
        <li><b>Input Annual Bonus Rate:</b> Provide the annual bonus rate as a percentage.</li>
        <li><b>Add Final Bonus:</b> If applicable, enter the final bonus amount that may be added at the end of the policy term.</li>
        <li><b>Click Calculate:</b> Press the “Calculate” button to get an instant estimate of the total maturity amount.</li>
        <li><b>View Results:</b> Review the detailed results, including the total maturity amount, and visualize the data through charts or tables.</li>
        <li><b>Export Data:</b> Use the export feature to save the results in an Excel file for further analysis or record-keeping.</li>
      </ol>
    </div>

    <p style={{fontFamily:"sans-serif"}}>The LIC Maturity Calculator is an invaluable resource for LIC policyholders aiming to plan their financial future with confidence. By providing detailed estimates and visualizations, it helps users understand their policy benefits and make informed financial decisions.</p>
  </div>
</div>

          </div>
        </div>
        <div className='col-md-3'>
          <Sidebar/>
        </div>
      </div>
    </div>
      
    
     

      
    </Layout>
  );
};

export default LicMaturityCalculator;
