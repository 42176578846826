import React, { useState , useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar';
import './main.css';
import './Responsive.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const PensionPlanCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [monthlyContribution, setMonthlyContribution] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [currentAge, setCurrentAge] = useState('');
  const [retirementAge, setRetirementAge] = useState('');
  const [payoutYears, setPayoutYears] = useState('20'); // default to 20 years
  const [result, setResult] = useState(null);

  // Pension Calculation
  const calculatePension = (e) => {
    e.preventDefault();
    
    const P = parseFloat(monthlyContribution);
    const r = parseFloat(interestRate) / 100; // Annual interest rate in decimal
    const C = parseInt(currentAge);
    const E = parseInt(retirementAge);
    const n = 12; // Compounding periods (monthly)
    const t = E - C; // Number of years until retirement
    const totalMonths = t * 12;
    
    // Future Value of Annuity Formula for Monthly Contributions
    const accumulatedCorpus = P * ((Math.pow(1 + (r / n), totalMonths) - 1) / (r / n)) * (1 + (r / n));

    // Annual Pension Calculation
    const annualPension = accumulatedCorpus / parseInt(payoutYears); // Payout spread over selected years

    const data = {
      labels: ['Total Savings', 'Annual Pension'],
      datasets: [{
        label: 'Pension Breakdown',
        data: [accumulatedCorpus, annualPension],
        backgroundColor: ['rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)'],
        borderWidth: 1
      }]
    };
    
    setResult({ accumulatedCorpus, annualPension, data });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Total Savings': result.accumulatedCorpus.toFixed(2) },
      { 'Annual Pension': result.annualPension.toFixed(2) }
    ]);
    
    XLSX.utils.book_append_sheet(wb, ws, 'Pension Plan Calculator Data');
    XLSX.writeFile(wb, 'Pension_Plan_Calculator.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout  pageTitle="LIC Pension Plan Calculator | LICCalculators"
    metaDescription="Use the LIC Pension Plan Calculator to calculate pension amounts based on your LIC plan. Get accurate pension projections."
    metaKeywords="LIC pension calculator, LIC pension plan, pension plan calculator"
    canonicalUrl="https://liccalculators.com/lic-pension-plan-calculator"
    robotsContent="index, follow"
    ogTitle="LIC Pension Plan Calculator | LICCalculators"
    ogDescription="Accurately calculate your pension amount with the LIC Pension Plan Calculator."
    ogUrl="http://localhost:3000/lic-pension-plan-calculator"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/lic-pension-plan-calculator",
      "name": "LIC Pension Plan Calculator",
      "description": "Use the LIC Pension Plan Calculator to calculate pension amounts based on your LIC plan.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">LIC Pension Plan Calculator</li>
                </ol>
              </nav>
              <form onSubmit={calculatePension}>
                <h1 className='text-center'>LIC Pension Plan Calculator</h1>
                <p className='text-center'>Calculate returns on your Investment Plan.</p>
                <div className='container text-center'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label htmlFor='MonthlyContribution' className='form-label label'>
                        <b>Monthly Contribution (₹):</b> <span className='text-danger'>*</span>
                      </label>
                      <input 
                        type="number" 
                        className='form-control' 
                        value={monthlyContribution} 
                        onChange={(e) => setMonthlyContribution(e.target.value)} 
                        id="investmentAmount" 
                        placeholder='Enter Monthly Contribution'
                        required 
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='InterestRate' className='form-label label'>
                        <b>Annual Interest Rate (%):</b><span className='text-danger'>*</span>
                      </label>
                      <input 
                        type="number"
                        className='form-control'
                        value={interestRate} 
                        onChange={(e) => setInterestRate(e.target.value)} 
                        id="investmentAmount" 
                        placeholder='Enter Annual Interest Rate'
                        required 
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='CurrentAge' className='form-label label'>
                        <b>Current Age:</b><span className="text-danger">*</span>
                      </label>
                      <input 
                        type="number"
                        className='form-control'
                        value={currentAge} 
                        onChange={(e) => setCurrentAge(e.target.value)} 
                        id="investmentAmount" 
                        placeholder='Enter Current Age'
                        required 
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='RetirementAge' className='form-label label'>
                        <b>Retirement Age:</b> <span className="text-danger">*</span>
                      </label>
                      <input 
                        type="number"
                        className='form-control'
                        value={retirementAge} 
                        onChange={(e) => setRetirementAge(e.target.value)} 
                        id="investmentPeriod" 
                        placeholder='Enter Retirement Age'
                        required 
                      />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='PayoutYears' className='form-label label'>
                        <b>Payout Period (years):</b>
                      </label>
                      <input 
                        type="number"
                        className='form-control'
                        value={payoutYears} 
                        onChange={(e) => setPayoutYears(e.target.value)} 
                        id="investmentPeriod" 
                        placeholder='Enter Payout Period in Year'
                        
                      />
                    </div>
                  </div>
                </div>
                <div className='row input-row'>
                  <div className='col-md-12'>
                    <button type="submit" className='btn btn-success' id="annualInterestRate" >Calculate</button>
                  </div>
                </div>
              </form>

              {result && (
                <div className='container'>
                  <hr />
                  <h5>Summary:</h5>
                  <div className='table-responsive'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <th scope='col'>Total Savings</th>
                          <td>{formatCurrency(result.accumulatedCorpus.toFixed(2))}</td>
                        </tr>
                        <tr>
                          <th scope='col'>Annual Pension</th>
                          <td>{formatCurrency(result.annualPension.toFixed(2))}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success" onClick={exportToExcel}>Export to Excel</button>
                  <div className='container'>
                    <Bar data={result.data} />
                  </div>
                </div>
              )}
              <div className='container'>
      <div className='row' style={{marginTop:"50px"}}>
        <hr ></hr>
        <h3 className='text-center text-black'>About Pension Plan Calculator</h3>
        <hr></hr>
        <p style={{fontFamily:"sans-serif"}}>A Pension Plan Calculator helps individuals estimate their retirement savings and income based on various factors such as monthly contributions, interest rate, and duration of the investment. This tool is essential for planning financial stability during retirement.</p>
        <p style={{fontFamily:"sans-serif"}}>Our Pension Plan Calculator simplifies this process by performing accurate calculations based on your inputs. It takes into account not only your contributions and interest rates but also the impact of inflation on your future savings. This is crucial because inflation erodes the purchasing power of your money over time. By adjusting for inflation, our tool provides a more realistic picture of how much your savings will be worth in today’s terms.</p>
        <h3>Key Inputs for the Pension Plan Calculator</h3>
        <br></br>
        <div style={{fontFamily:"sans-serif"}}>
        <ul>
          <li><b>Monthly Contribution (P):</b> The amount you contribute regularly (monthly) to the pension plan.</li>
          <li><b>Annual Interest Rate (r):</b> The expected rate of return on the invested money (usually in percentage).</li>
          <li><b>Investment Period (n):</b> The duration of the investment until retirement (in years).</li>
          <li><b>Expected Retirement Age (E):</b> The age when you plan to retire.</li>
          <li><b>Current Age (C):</b> Your current age.</li>
        </ul>

        </div>
        <p style={{fontFamily:"sans-serif"}}>Here’s how it works: Input your monthly contribution, the annual interest rate of your pension plan, and the expected inflation rate. Specify your current age and the age at which you plan to retire. The calculator then projects the future value of your contributions and calculates the annual pension you can expect to receive during retirement.</p>
        <p style={{fontFamily:"sans-serif"}}>The results are displayed clearly, showing both the total accumulated savings and the annual pension, adjusted for inflation. Additionally, you can visualize these results using a bar chart, making it easier to understand the breakdown of your savings and pension.</p>
        <p style={{fontFamily:"sans-serif"}}>Whether you're just starting to plan for retirement or reviewing your current strategy, our Pension Plan Calculator is a valuable tool to help you make informed decisions and ensure a comfortable retirement.</p>
      </div>
     </div>
            </div>
          </div>
          <div className='col-md-3'>
             <Sidebar/>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PensionPlanCalculator;
