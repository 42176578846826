import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Automatically register chart components
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar';

const LicSurrenderValueCalculator = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [sumAssured, setSumAssured] = useState('');
  const [policyTerm, setPolicyTerm] = useState('');
  const [yearsCompleted, setYearsCompleted] = useState('');
  const [annualPremium, setAnnualPremium] = useState('');
  const [premiumsPaid, setPremiumsPaid] = useState('');
  const [bonus, setBonus] = useState('');
  const [surrenderPercentage, setSurrenderPercentage] = useState(30); // Default 30%
  const [policyType, setPolicyType] = useState('Endowment');
  const [surrenderValue, setSurrenderValue] = useState(null);
  const [chartData, setChartData] = useState({});

  const getSurrenderPercentage = (yearsCompleted) => {
    if (policyType === 'Endowment') {
      return yearsCompleted < 3 ? 0 : 30;
    } else if (policyType === 'Whole Life') {
      return yearsCompleted < 5 ? 0 : 40;
    } else if (policyType === 'Term Insurance') {
      return 0; // Term Insurance typically has no surrender value
    }
    return surrenderPercentage;
  };

  // Calculate surrender value
  const calculateSurrenderValue = (e) => {
    e.preventDefault();

    // Ensure all required fields are filled
    if (!sumAssured || !policyTerm || !yearsCompleted || !annualPremium || !premiumsPaid || !bonus) {
      alert('Please fill in all required fields.');
      return;
    }

    // Convert inputs to numbers for calculation
    //eslint-disable-next-line
    const parsedSumAssured = parseFloat(sumAssured);
    //eslint-disable-next-line
    const parsedPolicyTerm = parseInt(policyTerm);
    const parsedYearsCompleted = parseInt(yearsCompleted);
    const parsedAnnualPremium = parseFloat(annualPremium);
    const parsedPremiumsPaid = parseInt(premiumsPaid);
    const parsedBonus = parseFloat(bonus);
    //eslint-disable-next-line
    const parsedSurrenderPercentage = parseFloat(surrenderPercentage) / 100;

    // Check if policy is eligible for surrender based on years completed
    if (parsedYearsCompleted < 3) {
      alert('Policies cannot be surrendered within the first 3 years.');
      return;
    }

    // Calculate the surrender percentage based on policy type
    const surrenderPercent = getSurrenderPercentage(parsedYearsCompleted) / 100;

    // Calculate total premiums paid so far
    const totalPremiumPaid = parsedAnnualPremium * parsedPremiumsPaid;

    // Basic surrender value calculation (total premiums paid * surrender percentage)
    const basicSurrenderValue = (totalPremiumPaid * surrenderPercent).toFixed(2);

    // Bonus amount
    const bonusAmount = parsedBonus ? parseFloat(parsedBonus) : 0;

    // Total surrender value is basic surrender value plus any bonus
    const totalSurrenderValue = (parseFloat(basicSurrenderValue) + bonusAmount).toFixed(2);

    // Update state with calculated values
    setSurrenderValue({
      basicSurrenderValue,
      bonusAmount: bonusAmount.toFixed(2),
      totalSurrenderValue,
    });

    // Generate chart data
    generateChart(basicSurrenderValue, bonusAmount, totalSurrenderValue);
  };

  // Generate chart data
  const generateChart = (basicSurrenderValue, bonusAmount, totalSurrenderValue) => {
    setChartData({
      labels: ['Basic Surrender Value (INR)', 'Bonus (INR)', 'Total Surrender Value (INR)'],
      datasets: [
        {
          label: 'LIC Surrender Value Breakdown',
          data: [basicSurrenderValue, bonusAmount, totalSurrenderValue],
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          borderWidth: 1,
        },
      ],
    });
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Surrender Value': surrenderValue.basicSurrenderValue },
      { 'Bonus Amount': surrenderValue.bonusAmount },
      { 'Total Surrender Value': surrenderValue.totalSurrenderValue }
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'Surrender Value');
    XLSX.writeFile(wb, 'Lic_surrender_value.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };

  return (
    <Layout   pageTitle="LIC Surrender Value Calculator | LICCalculators"
    metaDescription="Calculate the surrender value of your LIC policy using the LIC Surrender Value Calculator."
    metaKeywords="LIC surrender value calculator, LIC policy surrender value, surrender value calculation"
    canonicalUrl="https://liccalculators.com/lic-surrender-value-calculator"
    robotsContent="index, follow"
    ogTitle="LIC Surrender Value Calculator | LICCalculators"
    ogDescription="Accurately calculate the surrender value of your LIC policy using the LIC Surrender Value Calculator."
    ogUrl="https://liccalculators.com/lic-surrender-value-calculator"
    ogImage="https://liccalculators.com/assets/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/lic-surrender-value-calculator",
      "name": "LIC Surrender Value Calculator",
      "description": "Calculate the surrender value of your LIC policy using the LIC Surrender Value Calculator.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" style={{ textDecoration: 'none', color: 'black' }}>Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/all-tools" style={{ textDecoration: 'none', color: 'black' }}>All Tools</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">Lic Surrender Value Calculator</li>
                </ol>
              </nav>

              <form>
                <h1 className='text-center'>Lic Surrender Value Calculator</h1>
                <p className='text-center'>Maximize Your Returns, Calculate Your LIC Surrender Value.</p>

                <div className='container text-center'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label htmlFor='Sum' className='form-label label'><b>Sum Assured (INR):</b><span className="text-danger">*</span></label>
                      <input
                        type="number"
                        value={sumAssured}
                        className='form-control'
                        id="investmentAmount" 
                        onChange={(e) => setSumAssured(e.target.value)}
                        placeholder='Enter Sum Assured Amount'
                        required
                      />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Policy' className='form-label label'><b>Policy Term (Years):</b><span className="text-danger">*</span></label>
                      <input
                        type="number"
                        value={policyTerm}
                        className='form-control'
                        id="investmentAmount" 
                        onChange={(e) => setPolicyTerm(e.target.value)}
                        placeholder='Enter Policy term'
                        required
                      />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Year' className='form-label label'><b>Years Completed:</b><span className="text-danger">*</span></label>
                      <input
                        type="number"
                        value={yearsCompleted}
                        id="investmentAmount" 
                        className='form-control'
                        onChange={(e) => setYearsCompleted(e.target.value)}
                        placeholder='Enter Year Completed'
                        required
                      />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Annual' className='form-label label'><b>Annual Premium (INR):</b><span className="text-danger">*</span></label>
                      <input
                        type="number"
                        value={annualPremium}
                        className='form-control'
                        id="investmentPeriod" 
                        onChange={(e) => setAnnualPremium(e.target.value)}
                        placeholder='Enter Annual Premium'
                        required
                      />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Premium' className='form-label label'><b>Number of Premiums Paid:</b><span className="text-danger">*</span></label>
                      <input
                        type="number"
                        value={premiumsPaid}
                        id="investmentPeriod" 
                        className='form-control'
                        onChange={(e) => setPremiumsPaid(e.target.value)}
                        placeholder='Enter Number of Premium Paid'
                        required
                      />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='Bonus' className='form-label label'><b>Bonus (INR):</b><span className="text-danger">*</span></label>
                      <input
                        type="number"
                        value={bonus}
                        className='form-control'
                        id="investmentPeriod" 
                        onChange={(e) => setBonus(e.target.value)}
                        placeholder='Enter Bonus Amount'
                        required
                      />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='surrender' className='form-label label'><b>Surrender Percentage (%):</b><span className="text-danger">*</span></label>
                      <input
                        type="number"
                        value={surrenderPercentage}
                        className='form-control'
                        id="investmentPeriod" 
                        onChange={(e) => setSurrenderPercentage(e.target.value)}
                        placeholder='Enter Surrender Percentage'
                        required
                      />
                    </div>
                  </div>

                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor='PolicyType' className='form-label label'><b>Policy Type:</b><span className="text-danger">*</span></label>
                      <select
                        value={policyType}
                        className='form-control'
                        id="investmentPeriod" 
                        onChange={(e) => setPolicyType(e.target.value)}
                        required
                      >
                        <option value="Endowment">Endowment</option>
                        <option value="Whole Life">Whole Life</option>
                        <option value="Term Insurance">Term Insurance</option>
                      </select>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-success mt-3" onClick={calculateSurrenderValue}>
                    Calculate Surrender Value
                  </button>
                </div>
              </form>

              {surrenderValue && (
                <div className="container">
                <hr></hr>
                 <h5>Summary :</h5> <br></br>
                 <div className='row'>
                  <div className='table-responsive'>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <th scope='col'>Basic Surrender Value</th>
                          <td>{formatCurrency(surrenderValue.basicSurrenderValue)}</td>
                        </tr>
                        <tr>
                          <th scope='col'>Bonus</th>
                          <td>{formatCurrency(surrenderValue.bonusAmount)}</td>
                        </tr>
                        <tr>
                           <th scope='col'>Total Surrender Value</th>
                           <td> {formatCurrency(surrenderValue.totalSurrenderValue)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <button className="btn btn-success mt-3" id='annualInterestRate'  onClick={exportToExcel}>
                    Export to Excel
                  </button>
                 </div>
                  
                  <div className='container'>
                    <div className='row'>
                    <h5 className='text-center' style={{marginTop:'20px'}}>Data In Graph Formate :</h5>
                    <Bar data={chartData} />
                    </div>
                  </div>
                  

                  
                
                </div>
              )}
            </div>
            <div className='container'>
  <div className='row'>
    <hr />
    <h3 className='text-center text-black'>About LIC Surrender Value Calculator</h3>
    <hr />
    <p style={{fontFamily:"sans-serif"}}>
      The LIC Surrender Value Calculator is an essential financial tool for policyholders who are considering surrendering their Life Insurance Corporation (LIC) policy before its maturity. This calculator offers a straightforward and efficient way to estimate the amount that will be refunded upon surrender, providing valuable insights into whether it is financially advantageous to keep or relinquish the policy.
    </p>
    <p style={{fontFamily:"sans-serif"}}>
      Surrendering a policy involves terminating it before its maturity date, which can occur for various reasons such as financial need, dissatisfaction with the policy terms, or changes in personal circumstances. The amount refunded, known as the surrender value, is typically a percentage of the premiums paid and may also include accrued bonuses, depending on the policy type and duration.
    </p>
    
    <h4>Key Inputs for the LIC Surrender Value Calculator:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Policy Term:</b> The total duration of the insurance policy from inception to maturity. The surrender value calculation is influenced by how long the policy has been active.</li>
        <li><b>Number of Premiums Paid:</b> The total number of years or months for which premiums have been paid. This figure affects the accumulation of the surrender value, as a longer payment history usually results in a higher refund.</li>
        <li><b>Sum Assured:</b> The guaranteed amount that the policyholder is entitled to receive in case of death or at maturity. This amount is a key factor in determining the surrender value.</li>
        <li><b>Bonus Amount:</b> Any bonuses accumulated over the policy term, which can significantly enhance the surrender value. Bonuses are additional amounts added to the policy as a reward for continuing the policy and are generally applicable to participating policies.</li>
      </ul>
    </div>

    <h4>Key Features:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Instant Calculation:</b> The calculator provides quick and accurate estimates of the surrender value based on the input parameters, allowing policyholders to make informed decisions promptly.</li>
        <li><b>Detailed Breakdown:</b> Offers a comprehensive breakdown of how the surrender value is calculated. This includes premiums paid, accrued bonuses, and any applicable deductions, giving users a clear understanding of how their refund is derived.</li>
        <li><b>Visual Representation:</b> Many calculators include graphical representations, such as charts or graphs, which help visualize the growth of the surrender value over time. This visual aid can be particularly useful for understanding trends and making comparisons.</li>
        <li><b>Comparison Tool:</b> Enables users to compare the surrender value of different policies or scenarios, helping them evaluate various options and choose the most beneficial course of action.</li>
      </ul>
    </div>

    <h4>Advantages:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Informed Decision Making:</b> The calculator equips policyholders with the information needed to make well-informed decisions regarding whether to continue with their policy or surrender it. It helps assess the financial implications of surrendering the policy versus maintaining it until maturity.</li>
        <li><b>Financial Planning:</b> By providing a clear estimate of the potential surrender value, the calculator assists in financial planning and budgeting. It helps users understand how surrendering the policy will impact their financial situation and plan accordingly.</li>
        <li><b>Convenience:</b> Using the calculator eliminates the need for direct communication with the insurance provider to obtain a surrender value estimate. It offers a convenient, user-friendly way to perform this calculation from the comfort of one’s home or office.</li>
        <li><b>Accurate Estimates:</b> The calculator ensures that users receive accurate estimates based on their current policy details. It reduces the risk of errors in manual calculations and provides reliable information that can be used for decision-making.</li>
        <li><b>Enhanced Understanding:</b> The detailed breakdown and visual aids offered by many calculators enhance users' understanding of their policy's value and the factors influencing the surrender value. This transparency helps in making better financial decisions.</li>
      </ul>
    </div>

    <p style={{fontFamily:'sans-serif'}}>
      The LIC Surrender Value Calculator is an invaluable tool for anyone considering surrendering their LIC policy. It simplifies the complex process of calculating the surrender value, providing policyholders with a clear picture of their potential refund. By offering instant, accurate estimates and detailed breakdowns, it supports informed decision-making and effective financial planning. Whether facing a financial emergency, contemplating a policy change, or evaluating investment options, this tool provides essential insights that help users make the best choices for their individual needs.
    </p>
  </div>
</div>

          </div>

          <div className='col-md-3'>
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LicSurrenderValueCalculator;
