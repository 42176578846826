import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import '../Pages/About.css';
import Sidebar from '../Pages/Sidebar';

// Register the components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function PersonalLoan() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [principal, setPrincipal] = useState(0);
  const [rate, setRate] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [emi, setEMI] = useState(0);
  const [totalPrincipal, setTotalPrincipal] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    setChartData({
      labels: ['EMI', 'Total Principal', 'Total Interest', 'Total Amount'],
      datasets: [{
        label: 'Values',
        data: [emi, totalPrincipal, totalInterest, totalAmount],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      }]
    });
  }, [emi, totalPrincipal, totalInterest, totalAmount]);

  const calculateEMI = (e) => {
    e.preventDefault();
    if (principal > 0 && rate > 0 && tenure > 0) {
      const monthlyRate = (rate / 100) / 12;
      const totalPayments = tenure * 12;
      const emiValue = (principal * monthlyRate * Math.pow((1 + monthlyRate), totalPayments)) / (Math.pow((1 + monthlyRate), totalPayments) - 1);
      const totalPaymentValue = emiValue * totalPayments;
      const totalInterestValue = totalPaymentValue - principal;

      setEMI(emiValue.toFixed(2));
      setTotalPrincipal(principal.toFixed(2));
      setTotalInterest(totalInterestValue.toFixed(2));
      setTotalAmount(totalPaymentValue.toFixed(2));
    } else {
      alert("Please enter valid values for all fields.");
    }
  };

  const formattedEmi = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(emi);
  const formattedPrincipal = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(totalPrincipal);
  const formattedInterest = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(totalInterest);
  const formattedAmount = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(totalAmount);

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Monthly EMI': formattedEmi },
      { 'Principal Amount': formattedPrincipal },
      { 'Total Interest': formattedInterest },
      { 'Total Amount': formattedAmount }
    ]);
    XLSX.utils.book_append_sheet(wb, ws, 'Loan Calculator Data');
    XLSX.writeFile(wb, 'Loan_Calculator_Data.xlsx');
  };

  return (
    <Layout   
    pageTitle="LIC Personal Loan Calculator | LICCalculators"
    metaDescription="Calculate your personal loan EMI, interest, and tenure using the LIC Personal Loan Calculator."
    metaKeywords="LIC personal loan calculator, personal loan EMI, LIC loan, interest rates"
    canonicalUrl="https://liccalculators.com/lic-personal-loan-calculator"
    robotsContent="index, follow"
    ogTitle="LIC Personal Loan Calculator | LICCalculators"
    ogDescription="Calculate your personal loan EMI, interest, and tenure using the LIC Personal Loan Calculator."
    ogUrl="https://liccalculators.com/lic-personal-loan-calculator"
    ogImage="https://liccalculators.com/img/new_logo.png"
    schemaData={{
      "@context": "https://schema.org",
      "@type": "WebPage",
      "url": "https://liccalculators.com/lic-personal-loan-calculator",
      "name": "LIC Personal Loan Calculator",
      "description": "Calculate your personal loan EMI, interest, and tenure using the LIC Personal Loan Calculator.",
      "publisher": {
        "@type": "Organization",
        "name": "LICCalculators",
        "logo": {
          "@type": "ImageObject",
          "url": "https://liccalculators.com/img/new_logo.png"
        }
      }
    }}
>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-8 main-section'>
            <div className='container'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/" style={{ textDecoration: 'none' }}>Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/all-tools" style={{ textDecoration: 'none' }}>All tools</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">LIC Personal Loan Calculator</li>
                </ol>
              </nav>
              <form onSubmit={calculateEMI}>
                <h1 className='text-center display-4'>LIC Personal Loan Calculator</h1>
                <p className='text-center'>Calculate your monthly EMI and total interest.</p>
                <div className='container text-center'>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor="loanAmount" className="form-label"><b>Principal Amount:</b><span className="text-danger">*</span></label>
                      <input type="number" className='form-control' id="investmentPeriod" value={principal} onChange={(e) => setPrincipal(parseFloat(e.target.value))} min='0' required />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor="interestRate" className="form-label"><b>Annual Interest Rate (%):</b><span className="text-danger">*</span></label>
                      <input type="number" className='form-control' id="investmentPeriod" value={rate} onChange={(e) => setRate(parseFloat(e.target.value))} min='0' required />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <label htmlFor="loanTenure" className="form-label"><b>Loan Tenure (years):</b><span className="text-danger">*</span></label>
                      <input type="number" className='form-control' id="investmentPeriod" value={tenure} onChange={(e) => setTenure(parseFloat(e.target.value))} min='0' required />
                    </div>
                  </div>
                  <div className='row input-row'>
                    <div className='col-md-12'>
                      <button type="submit" className="btn btn-primary">Calculate</button>
                    </div>
                  </div>
                </div>
              </form>
              {totalAmount > 0 && (
                <div className='container'>
                  <hr />
                  <div className='row'>
                    <div className='col-md-12'>
                      <Bar 
                        data={chartData} 
                        options={{
                          scales: {
                            y: {
                              beginAtZero: true
                            }
                          }
                        }} 
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <h5>Summary :</h5>
                    <div className="table-responsive">
                      <table className='table'>
                        <tbody>
                          <tr>
                            <th scope='col'>Monthly EMI:</th>
                            <td className='text-black'>{formattedEmi}</td>
                          </tr>
                          <tr>
                            <th scope='col'>Total Principal:</th>
                            <td className='text-black'>{formattedPrincipal}</td>
                          </tr>
                          <tr>
                            <th scope='col'>Total Interest:</th>
                            <td className='text-black'>{formattedInterest}</td>
                          </tr>
                          <tr>
                            <th scope='col'>Total Amount:</th>
                            <td className='text-black'>{formattedAmount}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <button className="btn btn-success" onClick={exportToExcel}>Download Excel</button>
                    </div>
                  </div>
                </div>
              )}
              <div className='container'>
  <div className='row'>
    <hr />
    <h3 className='text-center text-black'>About LIC Personal Loan Calculator</h3>
    <hr />
    <p style={{fontFamily:"sans-serif"}}>
      The LIC Personal Loan Calculator is a practical financial tool designed to help individuals estimate their monthly loan payments and overall repayment schedule for personal loans provided by the Life Insurance Corporation of India (LIC). This tool simplifies the complex process of calculating loan details, offering users an easy way to understand the financial commitments associated with their loan.
    </p>
    <p style={{fontFamily:"sans-serif"}}>
      A personal loan from LIC can be used for a variety of purposes, such as funding a major expense, consolidating debt, or covering unexpected financial needs. By using the LIC Personal Loan Calculator, borrowers can get a clear view of their monthly EMI (Equated Monthly Installment), total interest payable, and the overall cost of the loan, based on their loan amount, interest rate, and tenure.
    </p>
    
    <h4>Key Inputs for the LIC Personal Loan Calculator:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Loan Amount:</b> The total sum of money borrowed from LIC. This is the principal amount that will be repaid over the loan period.</li>
        <li><b>Annual Interest Rate:</b> The rate at which interest will accrue on the loan amount. This is typically expressed as an annual percentage rate (APR) and significantly affects the total repayment amount.</li>
        <li><b>Loan Tenure:</b> The duration for which the loan will be repaid, usually expressed in months or years. A longer tenure generally results in lower monthly EMIs but a higher total interest cost.</li>
      </ul>
    </div>

    <h4>Key Features:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Instant Calculation:</b> Provides immediate calculations of monthly EMIs, total interest payable, and the overall loan repayment amount based on the inputs provided, allowing users to quickly assess their financial commitment.</li>
        <li><b>Detailed Breakdown:</b> Offers a comprehensive breakdown of how the EMI is calculated, including the principal and interest components. This helps users understand the allocation of their payments over the loan period.</li>
        <li><b>Amortization Schedule:</b> Generates an amortization schedule that outlines the repayment plan for each installment, showing how much of each EMI goes towards the principal and how much towards the interest.</li>
        <li><b>Graphical Representation:</b> Many calculators include graphs or charts to visualize the repayment schedule and interest payments over time, making it easier to understand the loan's financial impact.</li>
        <li><b>Comparison Tool:</b> Allows users to compare different loan scenarios by adjusting the loan amount, interest rate, or tenure, helping them choose the most suitable loan terms based on their financial situation.</li>
      </ul>
    </div>

    <h4>Advantages:</h4>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Informed Decision Making:</b> Helps borrowers make informed decisions about taking out a personal loan by providing a clear picture of their monthly obligations and total repayment amount.</li>
        <li><b>Financial Planning:</b> Assists in budgeting and financial planning by outlining the loan's impact on monthly expenses and overall financial commitments. This helps users plan their finances more effectively.</li>
        <li><b>Convenience:</b> Offers a user-friendly interface for calculating loan details without the need for complex manual calculations or direct consultations with financial institutions.</li>
        <li><b>Accurate Estimates:</b> Ensures accurate loan calculations based on user inputs, reducing the risk of errors and providing reliable estimates for financial planning purposes.</li>
        <li><b>Enhanced Understanding:</b> Provides a detailed breakdown and visual aids to help users better understand how their loan payments are structured and how interest accumulates over time.</li>
      </ul>
    </div>

    <p style={{fontFamily:'sans-serif'}}>
      The LIC Personal Loan Calculator is an invaluable tool for anyone considering taking out a personal loan from LIC. It simplifies the process of calculating monthly payments, total interest, and overall repayment costs, providing users with a clear understanding of their financial obligations. By offering instant calculations, detailed breakdowns, and visual representations, the calculator supports informed decision-making and effective financial planning. Whether you are planning a major purchase, managing debt, or facing unexpected expenses, this tool helps you navigate the loan process with confidence and clarity.
    </p>
  </div>
</div>


            </div>
          </div>
          <div className='col-md-3'>
            <Sidebar></Sidebar>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PersonalLoan;
