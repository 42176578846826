import React, { useState , useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import Layout from '../Componets/Layout';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Sidebar from '../Pages/Sidebar'

function LICLoanCalculator() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [principal, setPrincipal] = useState('');
  const [rate, setRate] = useState('');
  const [tenure, setTenure] = useState('');
  const [emi, setEMI] = useState(null);
  const [totalPrincipal, setTotalPrincipal] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [errors, setErrors] = useState({});

  const validateInputs = () => {
    const newErrors = {};
    if (!principal || principal <= 0) {
      newErrors.principal = "Principal amount must be greater than 0";
    }
    if (!rate || rate <= 0 || rate > 100) {
      newErrors.rate = "Interest rate must be between 0 and 100";
    }
    if (!tenure || tenure <= 0) {
      newErrors.tenure = "Loan tenure must be greater than 0 years";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const calculateEMI = (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }
    
    const monthlyRate = (rate / 100) / 12;
    const totalPayments = tenure * 12;
    const emiValue = (principal * monthlyRate * Math.pow((1 + monthlyRate), totalPayments)) / (Math.pow((1 + monthlyRate), totalPayments) - 1);
    const totalPaymentValue = emiValue * totalPayments;
    const totalInterestValue = totalPaymentValue - principal;

    setEMI(emiValue.toFixed(2));
    setTotalPrincipal(principal);
    setTotalInterest(totalInterestValue.toFixed(2));
    setTotalAmount(totalPaymentValue.toFixed(2));
  };



  const chartData = {
    labels: ['Monthly EMI', 'Total Principal', 'Total Interest', 'Total Amount'],
    datasets: [
      {
        label: 'Loan Details',
        data: [emi, totalPrincipal, totalInterest, totalAmount],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(153, 102, 255, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
 
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([
      { 'Monthly Emi': emi },
      { 'Total Principal:': totalPrincipal },
      { 'Estimated Interest': totalInterest },
      { 'Total Amount': totalAmount },
    ]);

    XLSX.utils.book_append_sheet(wb, ws, 'Lic Loan Calculator Data');
    XLSX.writeFile(wb, 'Lic_loan_Calculator.xlsx');
  };

  const formatCurrency = (amount) => {
    const formatter = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    return formatter.format(amount);
  };
  return (
   <Layout  pageTitle="LIC Loan Calculator | LICCalculators"
   metaDescription="Use the LIC Loan Calculator to calculate EMI, principal, and interest for your LIC loans easily."
   metaKeywords="LIC loan calculator, loan EMI calculator, LIC loan, interest rates"
   canonicalUrl="https://liccalculators.com/lic-loan-calculator"
   robotsContent="index, follow"
   ogTitle="LIC Loan Calculator | LICCalculators"
   ogDescription="Accurately calculate your loan EMI, interest, and tenure using the LIC Loan Calculator on LICCalculators."
   ogUrl="https://liccalculators.com/lic-loan-calculator"
   ogImage="https://liccalculators.com/img/new_logo.png"
   schemaData={{
     "@context": "https://schema.org",
     "@type": "WebPage",
     "url": "https://liccalculators.com/lic-loan-calculator",
     "name": "LIC Loan Calculator",
     "description": "Use the LIC Loan Calculator to calculate EMI, principal, and interest for your LIC loans easily.",
     "publisher": {
       "@type": "Organization",
       "name": "LICCalculators",
       "logo": {
         "@type": "ImageObject",
         "url": "https://liccalculators.com/img/new_logo.png"
       }
     }
   }}>
     <div className="container-fluid">
     <div className='row'>
      <div className='col-md-1'>

      </div>
      <div className='col-md-8 main-section'>
        <div className='container'>
        <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to="/" style={{textDecoration:'none', color:'black'}}>Home</Link></li>
        <li className="breadcrumb-item"><Link to="/all-tools" style={{textDecoration:'none' , color:'black'}}>All Tools</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Lic Loan Calculator</li>
      </ol>
        </nav>
        <form onSubmit={calculateEMI}>
        <h1 className='text-center'>
        Lic Loan Calculator
        </h1>
        <p className='text-center'>Your Trusted Partner in Smarter Loan Planning.</p>
        <div className='container text-center'>
          <div className='row'>
            <div className='col-md-12'>
            <label htmlFor='principal' className='form-label label'><b>Principal Amount (₹):</b><span className='text-danger'>*</span></label>
          <input
            type="number"
            className={`form-control ${errors.principal ? 'is-invalid' : ''}`}
            id='investmentAmount'
            value={principal}
            onChange={(e) => setPrincipal(parseFloat(e.target.value))}
              placeholder='Enter Principal Amount'
            required
          />
          {errors.principal && <div className="invalid-feedback">{errors.principal}</div>}
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='Annual' className='form-label label'><b>Annual Interest Rate (%):</b> <span className='text-danger'>*</span></label>
          <input
            type="number"
            className={`form-control ${errors.rate ? 'is-invalid' : ''}`}
            id='annualInterestRate'
            value={rate}
            onChange={(e) => setRate(parseFloat(e.target.value))}
            placeholder='Enter Annual Interest Rate'
            required
          />
          {errors.rate && <div className="invalid-feedback">{errors.rate}</div>}
            </div>
          </div>
          <div className='row input-row'>
            <div className='col-md-12'>
            <label htmlFor='loan' className='form-label label'><b>Loan Tenure (years):</b> <span className='text-danger'>*</span></label>
          <input
            type="number"
            className={`form-control ${errors.tenure ? 'is-invalid' : ''}`}
            id='annualInterestRate'
            value={tenure}
            onChange={(e) => setTenure(parseFloat(e.target.value))}
            placeholder='Enter Loan Tenure'
            required
          />
          {errors.tenure && <div className="invalid-feedback">{errors.tenure}</div>}
            </div>
          </div>
      
        </div>
        <div className='row input-row'>
            <div className='col-md-12'>
            <button type="submit" id='annualInterestRate' className="btn btn-success">Calculate</button>
            </div>
          </div>
         </form>

         {emi && (
        <div className="container">
        <hr></hr>
          <h3>Loan Summary :</h3> <br></br>
          <div className='row'>
            <div className='table-responsive'>
              <table className='table'>
                <tbody>
                  <tr>
                    <th scope="col">Monthly EMI:</th>
                    <td>{formatCurrency(emi)}</td>
                  </tr>
                  <tr>
                    <th scope='col'>Total Principal:</th>
                    <td>{formatCurrency(totalPrincipal)}</td>
                  </tr>
                  <tr>
                    <th scope='col'>Total Interest:</th>
                    <td>{formatCurrency(totalInterest)}</td>
                  </tr>
                  <tr>
                    <th scope='col'>Total Amount:</th>
                    <td>{formatCurrency(totalAmount)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button id='annualInterestRate' className="btn btn-success" onClick={exportToExcel} >Export to Excel</button>
          </div>

          <div className='container'>
            <div className='row'>
            <h5 className='text-center' style={{marginTop:'20px'}}>Data In Graph Formate :</h5>
            <Bar data={chartData} />
            </div>
          </div>
        

       
        </div>
      )}
      <div className='container'>
  <div className='row' style={{marginTop:"50px"}}>
    <hr></hr>
    <h3 className='text-center text-black'>About LIC Loan Calculator Tools</h3>
    <hr></hr>
    <p style={{fontFamily:'sans-serif'}}>The LIC Loan Calculator is an online tool designed to help users calculate the details of a loan taken from the Life Insurance Corporation of India (LIC). This tool simplifies the process of loan planning by allowing users to input critical information like the Principal Amount, Annual Interest Rate, and Loan Tenure (in years). Based on these inputs, the calculator provides users with key financial insights including:</p>
    
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Monthly EMI:</b> The fixed monthly payment that needs to be made towards the loan.</li>
        <li><b>Total Principal:</b> The original amount borrowed, which needs to be repaid.</li>
        <li><b>Total Interest:</b> The amount of interest charged over the loan tenure.</li>
        <li><b>Total Amount:</b> The sum of the principal and interest that needs to be repaid over the loan period.</li>
      </ul>
    </div>

    <h5>Key Features of the LIC Loan Calculator Tool:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Accurate Loan Estimates:</b> By entering key loan parameters, users get a precise breakdown of their EMI, total principal, total interest, and total repayment amount, helping them plan their finances effectively.</li>
        <li><b>Interactive Charts:</b> The tool includes graphical representations of the loan breakdown, allowing users to visualize how their loan payments are distributed between principal and interest over the tenure.</li>
        <li><b>Export to Excel:</b> Users can easily export the loan details and payment schedule into an Excel sheet for deeper analysis or future reference. This is especially useful for comparing multiple loan scenarios.</li>
        <li><b>Validation Checks:</b> The tool includes validation for inputs to ensure accuracy. For instance, the principal amount must be greater than 0, the interest rate must be within a reasonable range (e.g., between 0% and 100%), and the loan tenure must be a positive number.</li>
      </ul>
    </div>

    <h5>Benefits of Using the LIC Loan Calculator Tool:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Financial Planning:</b> The tool helps users understand their monthly and overall loan repayment commitments, allowing them to plan their finances better. Whether you're taking a loan against your LIC policy or for personal needs, it ensures that you’re well-prepared for future payments.</li>
        <li><b>Loan Comparison:</b> The calculator can be used to compare different loan scenarios by adjusting the principal amount, interest rate, or loan tenure. This helps users select the most favorable loan option based on their financial situation.</li>
        <li><b>Easy Accessibility:</b> The tool is available online, making it easily accessible to users at any time. They can experiment with different loan parameters, try out what-if scenarios, and instantly view results.</li>
        <li><b>Time-Saving:</b> Instead of manually calculating loan amounts, the LIC Loan Calculator instantly computes all necessary values, saving time and effort while ensuring accurate results.</li>
        <li><b>Zero Errors:</b> Manual calculations can often result in errors, but with the calculator’s automated process, users are assured of error-free results, leading to better financial decision-making.</li>
      </ul>
    </div>

    <h5>How the LIC Loan Calculator Works:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <p>The LIC Loan Calculator uses basic financial formulas to compute the monthly EMI and total loan amount based on the inputs provided. Here’s a quick guide to how the calculations are performed:</p>
      <ul>
        <li><b>Monthly EMI:</b> The EMI is calculated using the following formula:
          <code>EMI = [P × r × (1 + r)^n] / [(1 + r)^n – 1]</code>, where:
          <ul>
            <li>P is the Principal Loan Amount</li>
            <li>r is the monthly interest rate (annual interest rate divided by 12)</li>
            <li>n is the Loan Tenure in months</li>
          </ul>
        </li>
        <li><b>Total Interest:</b> The interest is calculated based on the loan tenure and principal amount using standard interest formulas. The total interest is the difference between the total repayment amount and the original principal.</li>
        <li><b>Total Amount:</b> This is the total repayment amount, which is simply the sum of the principal amount and the total interest.</li>
      </ul>
      <p>The calculator automates these calculations, providing users with a breakdown of the amounts instantly.</p>
    </div>

    <h5>Steps to Use the LIC Loan Calculator Tool:</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li>Enter the desired Principal Amount (the loan amount you wish to borrow).</li>
        <li>Input the Annual Interest Rate that applies to the loan.</li>
        <li>Provide the Loan Tenure in years (the period over which you intend to repay the loan).</li>
        <li>Click “Calculate” to instantly view your monthly EMI, total interest, and overall repayment amount.</li>
        <li>If desired, export the results to an Excel file for further analysis or sharing with others.</li>
      </ul>
    </div>

    <h5>Why Choose LIC for Loan Services?</h5>
    <div style={{fontFamily:'sans-serif'}}>
      <ul>
        <li><b>Trust and Reliability:</b> As India’s largest and most trusted insurance provider, LIC offers a secure and reliable option for individuals looking to take loans against their policies.</li>
        <li><b>Lower Interest Rates:</b> Loans against LIC policies generally come with lower interest rates compared to personal loans or other unsecured loans, making it a more affordable option.</li>
        <li><b>Flexible Loan Terms:</b> LIC offers flexible loan repayment terms, allowing borrowers to choose tenures that suit their financial capabilities and goals.</li>
        <li><b>No Credit Check:</b> Since the loan is secured against your LIC policy, there’s typically no requirement for a credit check, making it easier for individuals with low credit scores to access loans.</li>
        <li><b>Policy Continuation:</b> Even after taking a loan, your LIC policy remains in force, providing continued life coverage to the insured, which ensures that your family is financially secure.</li>
      </ul>
    </div>

    <p style={{fontFamily:'sans-serif'}}>The LIC Loan Calculator is a powerful and user-friendly tool that makes loan planning simple and transparent. Whether you’re borrowing for personal needs or against your LIC policy, this calculator helps you make informed decisions with confidence.</p>
  </div>
</div>

        </div>
      </div>
      <div className='col-md-3'>
        <Sidebar />
      </div>
     </div>
    </div>
   </Layout>
  );
}

export default LICLoanCalculator;
